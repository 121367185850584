<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      :text="loadingtext"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="all-advicers" v-if="advicerSelectedList.length === 0">
      <h5>
        دستورالعمل های ثبت شده توسط تمام متخصصان
        <button @click="GetAdvicerFilter()">انتخاب متخصص</button>
      </h5>
    </div>
    <div class="advicer-list" v-else>
      <horizontal-scroll class="horizontal-scroll">
        <div
          class="advicer-item"
          v-for="(item, index) of advicerSelectedList"
          :key="index"
        >
          <h6>{{ item.name }}</h6>
          <i
            class="fa fa-window-close"
            aria-hidden="true"
            @click="toggleFilterList(item.id, item.name)"
          ></i>
        </div>
      </horizontal-scroll>
    </div>
    <VueModal
      v-model="modelShowState"
      title="لیست متخصصان شما"
      wrapper-class="animate__animated animate__faster"
      modal-class="drawer"
      in-class="animate__fadeInRight"
      out-class="animate__fadeOutRight"
    >
      <div class="advicer-filter-list">
        <div class="filter-item-list">
          <div
            class="filter-item"
            :class="
              advicerSelectedList.some((x) => x.id === item.advicerId)
                ? 'filter-item filter-item-select'
                : 'filter-item'
            "
            v-for="(item, index) of advicerList"
            :key="index"
            @click="toggleFilterList(item.advicerId, item.fullName)"
          >
            <i
              :class="
                advicerSelectedList.some((x) => x.id === item.advicerId)
                  ? 'fa fa-check-circle-o'
                  : 'fa fa-circle-o'
              "
              aria-hidden="true"
            ></i>
            <h4>
              {{ item.fullName }} ({{ item.phone }}) تعداد زمین :
              {{ item.farmCount }}
            </h4>
          </div>
        </div>
        <button class="filter-btn" @click="filterList()">نمایش</button>
      </div>
    </VueModal>
  </div>
</template>

    <script>
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";
import { mapActions } from "vuex";
export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      loading: false,
      loadingtext: "",
      modelShowState: false,
      advicerSelectedList: [],
      advicerList: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionFarmerAdvicer", ["GetAllFarmerAdvicer"]),

    async GetAdvicerFilter() {
      this.loading = true;
      this.loadingtext = "دریافت لیست متخصصان";
      let result = await this.GetAllFarmerAdvicer({
        searchName: "",
      });
      this.loading = false;
      this.modelShowState = true;
      this.advicerList = result.data.map((item) => {
        return {
          advicerId: item.advicerId,
          fullName: item.fullName,
          phone: item.phone,
          farmCount: item.farmCount,
        };
      });
    },
    async toggleFilterList(advicerId, fullName) {
      if (this.advicerSelectedList.some((x) => x.id == advicerId)) {
        const indexOfObject = this.advicerSelectedList.findIndex((object) => {
          return object.id === advicerId;
        });
        this.advicerSelectedList.splice(indexOfObject, 1);
      } else {
        this.advicerSelectedList.push({
          id: advicerId,
          name: fullName,
        });
      }
      await this.filterList();
    },
    async filterList() {
      let id = [];
      this.advicerSelectedList.forEach((element) => {
        id.push(element.id);
      });
      this.$emit("getTips", id);
    },
  },
  mounted() {},
  created() {},
};
</script>

    <style scoped>
.main-box {
  width: 100%;
  height: 7vh;
  float: right;
  overflow: hidden;
}
.all-advicers {
  float: right;
  width: 96%;
  background-color: white;
  margin: 1em 2em;
  height: 5vh;
  box-shadow: 0px 3px 11px -8px #000;
  border-radius: 10px;
}
.all-advicers h5 {
  float: right;
  width: 100%;
  text-align: center;
  padding: 0.7em 0em;
  color: #2eb85c;
}
.all-advicers h5 button {
  border: none;
  background-color: #ffffff;
  padding: 0.2em 2em;
  font-size: 0.8em;
  color: #009130;
  border-radius: 26px;
  border: 1px dashed darkgreen;
  margin-right: 2em;
  font-weight: bold;
  transition: all 300ms;
}
.all-advicers h5 button:hover {
  background-color: #009130;
  color: #fff;
  border: 1px solid darkgreen;
}

.horizontal-scroll {
  display: flex;
  width: 100%;
}
.advicer-list {
  float: right;
  width: 96%;
  background-color: #d1d1d12e;
  margin: 1em 2em;
  height: 5vh;
  border-radius: 15px;
  border: 1px solid #2eb85c;
  overflow-y: hidden;
  overflow-x: auto;
}
.advicer-item {
  float: right;
  width: fit-content;
  margin: 0.4em;
  background-color: #00ff55;
  border-radius: 12px;
  color: #4f5d73;
  text-align: center;
  padding: 0.5em 1em 0em 1em;
  display: flex;
}
.advicer-item h6 {
  float: right;
  width: fit-content;
  margin-right: 1em;
  margin-left: 1em;
  color: #000;
  font-family: "Dirooz";
}
.advicer-item i {
  float: right;
  color: #eb9494;
  cursor: pointer;
}
.advicer-item i:hover {
  color: red;
}

.advicer-filter-list {
  float: right;
  width: 25vw;
  height: 90vh;
}
.filter-item-list {
  float: right;
  width: 100%;
  height: 86vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.filter-btn {
  float: left;
  width: 100%;
  border: none;
  height: 4vh;
  background-color: #20a8d8;
  border-radius: 16px;
}
.filter-btn:hover {
  background-color: #0052d5;
  color: #fff;
}
.filter-item {
  float: right;
  margin: 0.5em 1.2em;
  background-color: whitesmoke;
  padding: 0.4em;
  box-shadow: -2px 2px 8px -5px #000;
  border-radius: 10px;
  cursor: pointer;
  color: darkgreen;
}
.filter-item:hover {
  background-color: limegreen;
  color: #000;
}
.filter-item-select {
  background-color: limegreen;
  color: #000;
}

.filter-item i {
  float: right;
  margin: 0.4em 0.6em;
  position: relative;
  font-size: 1.2em;
}
.filter-item h4 {
  float: right;
  font-size: 1em;
  font-family: "Gandom";
  margin: 0.4em 1em;
}
</style>
