<template>
    <div>
      <advicerList @getTips="refreshTips" />
      <tipsList :userId="userIds" />
    </div>
  </template>
  
  <script>
  import advicerList from "./subForm/advicerList";
  import tipsList from "./subForm/tipsList";
  export default {
    components: {
      advicerList,
      tipsList,
    },
    data() {
      return {
        userIds: [],
      };
    },
    computed: {},
    methods: {
      refreshTips(ids) {
        this.userIds = ids;
      },
    },
    mounted() {},
    created() {},
  };
  </script>
  
  <style>
  </style>