<template>
  <div class="main-box">
    <vue-element-loading
      :active="loading"
      :text="loadingtext"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div class="tips-list">
      <div class="tips-item" v-for="(item, index) of tipsList" :key="index">
        <div class="item-info">
          <img
            class="farmImg"
            :src="item.image"
            @error="
              () => (item.image = require(`@/assets/img/farmImageMapBox.jpg`))
            "
          />
          <h4>{{ item.title }}</h4>
          <h4>{{ item.product }}</h4>
          <h4>{{ item.stateCity }}</h4>
          <h4>{{ item.districtVilage }}</h4>
        </div>
        <div class="item-desc">
          <h5>{{ item.userFullName }} ({{ item.tipsTitle }})</h5>
          <p>
            {{ item.tipsDesc }}
          </p>
          <span style="cursor: pointer">
            <i class="fa fa-commenting-o" aria-hidden="true"></i>
            {{ item.replyCount }}
          </span>
          <span style="margin-left: 2em">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            {{ item.tipsDateTime }}
          </span>
          <a
            v-for="(fitem, findex) of item.tipsFiles"
            :key="findex"
            :href="downloadLink(fitem)"
            target="_blank"
            ><i class="fa fa-download" aria-hidden="true"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiUrlRoot } from "../../../../constants/config";
import { mapActions } from "vuex";
export default {
  components: {},
  props: ["userId"],
  data() {
    return {
      loading: false,
      loadingtext: "",
      tipsList: [],
    };
  },
  watch: {
    userId: function () {
      this.GetAllTips();
    },
  },
  computed: {},
  methods: {
    ...mapActions("tips", ["GetAllEmployerTips"]),

    async GetAllTips() {
      this.loading = true;
      this.tipsList = [];
      let result = await this.GetAllEmployerTips({
        userId: this.userId,
        userType: "Advicer",
      });
      this.loadingtext = "دریافت لیست دستورالعمل های کارمندان";
      if (result.succeeded) {
        result.data.forEach((element) => {
          this.tipsList.push({
            title: element.title,
            image: `${apiUrlRoot}Farm/${element.image}`,
            product: element.product,
            stateCity: element.stateCity,
            districtVilage: element.districtVilage,
            userFullName: element.userFullName,
            tipsTitle: element.tipsTitle,
            tipsDesc: element.tipsDesc,
            replyCount: element.replyCount,
            tipsDateTime: element.tipsDateTime,
            tipsFiles: element.tipsFiles,
          });
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "info",
        });
      }
      this.loading = false;
    },
    downloadLink(fileName) {
      return `${apiUrlRoot}/TipsFile/${fileName}`;
    },
  },
  mounted() {
    this.GetAllTips();
  },
  created() {},
};
</script>

    <style scoped>
.main-box {
  width: 100%;
  height: 83vh;
  float: right;
  overflow: hidden;
}
.tips-list {
  float: right;
  width: 98%;
  height: 80vh;
  margin: 1em;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
}
.tips-item {
  float: right;
  width: 98%;
  height: fit-content;
  margin: 1em;
  box-shadow: 0px 2px 13px -6px #004918;
  border-radius: 9px;
}
.item-info {
  float: right;
  clear: right;
  width: 13vw;
  margin: 0.5em;
}
.item-info img {
  float: right;
  width: 16em;
  min-height: 27vh;
  height: 27vh;
}
.item-info h4 {
  float: right;
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid #03c503;
  margin: 1px 0px;
  padding-right: 0.4em;
  font-family: "Dirooz";
  color: black;
  font-size: 1em;
  border-radius: 0px 4px 4px 0px;
}
.item-desc {
  float: right;
  width: 65vw;
  height: fit-content;
  margin: 1em;
  min-height: 32vh;
}
.item-desc h5 {
  float: right;
  width: 100%;
  text-align: center;
  color: limegreen;
}
.item-desc p {
  float: right;
  background-color: whitesmoke;
  padding: 0.6em;
  font-family: "Dirooz";
  font-weight: bold;
  height: 27vh;
  margin: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}
.item-desc span {
  float: right;
  margin: 0.8em 2em 0em 0em;
  color: #6d6d6d;
}
.item-desc span i {
  float: left;
  color: darkgreen;
  margin-right: 0.3em;
}
.item-desc a {
  float: right;
  margin: 0.5em 0.5em 0em 0em;
  color: #009726;
  font-size: 1.2em;
}
</style>
